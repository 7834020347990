import {Box, Stack} from "@mui/material";
import {FC} from "react";
import {SectionCardV2Props} from "./SectionCardV2.interface";
import {useCommonMediaQueries} from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export const SectionCardV2: FC<SectionCardV2Props> = ({url, imageUrl, title, description}) => {
    const { xxSmallScreen: xs, smallScreen: sm, largeScreen: lg } = useCommonMediaQueries()
    const cardWidth = xs ? '200px' : sm ? '300px' : lg ? '400px' : '18.9vw'
    const padding = xs ? '8px' : sm ? '8px' : lg ? '12px' : '1vw'
    const descriptionSize = xs ? '18px' : sm ? '27px' : lg ? '26px' : '1.1vw'
    const titleSize = xs ? '24px' : sm ? '36px' : lg ? '48px' : '2.3vw'

    return <Box width={cardWidth} minWidth={cardWidth} maxWidth={cardWidth}>
        <Stack  sx={{ backgroundColor: '#404D5A' }} borderRadius={'22px'} padding={padding} height={'100%'}>
            <Box
                p={'3px'}
                width={'100%'}
                borderRadius={'8px'}
                sx={{
                    aspectRatio: '1/1',
                    background: 'linear-gradient(to bottom, #FFEFD6 0%, #F7CF78 50%, #B6864A 100%)',
                }}
            >
                <Box
                    borderRadius={'5px'}
                    height={'100%'}
                    width={'100%'}
                    sx={theme => ({
                        backgroundColor: theme.palette.background.paper,
                        backgroundImage: `url(${imageUrl})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                    })}
                />
            </Box>

            <Typography color={'primary'} fontSize={titleSize} sx={{userSelect: 'none'}} lineHeight={0.8} py={padding}>
                {title}
            </Typography>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} flexGrow={1} gap={'8px'}>
                <Typography fontSize={descriptionSize} fontWeight={500} lineHeight={1} sx={{userSelect: 'none'}}>
                    {description}
                </Typography>
                <Box alignSelf={'end'}>
                    <Link to={url}>
                        <IconButton color={'primary'} sx={{borderColor: 'inherit', borderWidth: '1px', borderStyle: 'solid'}}>
                            <ArrowForwardIcon/>
                        </IconButton>
                    </Link>
                </Box>
            </Stack>
        </Stack>
    </Box>
}