import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import { Box, Stack } from "@mui/material";
import { Header } from "./header/Header";
import { OverlaySpinner } from "../common/loaders/OverlaySpinner";
import { GameWindow } from "../games/GameWindow";
import { usePlayGames } from "../../features/play_games/usePlayGames";
import { Footer } from "./footer/Footer";
import { BottomMobileMenu } from "./bottom_menu/BottomMobileMenu";
import { useCommonMediaQueries } from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import TopBanner from '../../../assets/bigcash/top_banner.webp'
import {CarouselSlider} from "./carousel_v2/CarouselSlider";

interface PageLayoutProps {
    children: ReactNode
    pageName?: string;
    carouselDisabled?: boolean
    footerDisabled?: boolean
    flexGrowContentDisabled?: boolean
    mobileBottomMenuDisabled?: boolean
    sportMode?: boolean
}

export const PageLayout: FC<PageLayoutProps> = ({ children, pageName, carouselDisabled = false, footerDisabled = false, flexGrowContentDisabled = false, mobileBottomMenuDisabled = false, sportMode = false }) => {
    const { isGameSessionLoading, gameSession, isOutcomeGameLoading } = usePlayGames()
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const [scrollContainerHeight, setScrollContainerHeight] = useState(0);
    const { smallScreen: sm, mediumScreen: md, largeScreen } = useCommonMediaQueries()
    // const [headerHeight, setHeaderHeight] = useState(0)

    useEffect(() => {
        const siteName = process.env.REACT_APP_SITE_NAME ?? "Casino"
        document.title = pageName ? `${siteName} | ${pageName}` : siteName;

        return () => {
            document.title = siteName;
        };
    }, [pageName]);

    useEffect(() => {
            if (scrollContainerRef.current) {
                const height = scrollContainerRef.current.offsetHeight;
                setScrollContainerHeight(height);
            }
    }, []);

    return <Stack
        height={'100dvh'}
        sx={{
            background: 'linear-gradient(to bottom, #0D182C 0%, #526368 40%, #97AEA4 100%)',
        }}
    >
        <Header
            // onHeightChange={setHeaderHeight}
        />
        <Stack
            ref={scrollContainerRef}
            flexGrow={1}
            sx={{overflowY: 'auto', scrollbarWidth: 'thin', scrollbarColor: '#15181D #15181D60'}}
        >
            <Stack position={'relative'} minHeight={scrollContainerHeight}>
                {sportMode ?
                    <>
                        {!carouselDisabled && <CarouselSlider/>}
                        {children}
                    </>
                    :
                    <>
                    <Box
                        sx={theme => ({
                            mx: 2,
                            width: `calc(100vw - ${theme.spacing(4)})`,
                            aspectRatio: '4/1',
                            backgroundImage: `url('${TopBanner}')`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center bottom',
                        })}
                    />
                    <Stack
                        mx={sm ? 0 :1}
                        mb={sm ? 2 : 4}
                        borderRadius={sm ? '14px' : md ? '20px' : '26px'}
                        sx={theme => ({
                            border: `${sm ? 5 : 10}px solid #E1BA73`,
                            backgroundColor: theme.palette.background.default
                        })}
                    >
                        {!carouselDisabled && <CarouselSlider/>}
                        {children}
                    </Stack>
                </>}


                {!flexGrowContentDisabled && <Box flexGrow={1}/>}
                {!footerDisabled && <Footer scrollContainerRef={scrollContainerRef}/>}
            </Stack>
                {(isOutcomeGameLoading || isGameSessionLoading) && <OverlaySpinner/>}
                {gameSession.SessionUrl && <GameWindow
                    // topOffset={headerHeight}
                />}
        </Stack>

        {largeScreen && !mobileBottomMenuDisabled && <BottomMobileMenu/>}
    </Stack>
}