import { FC } from "react";
// import { SectionCard } from "./SectionCard";
// import FlatColorBg from "../../../../assets/oropuroV2/section_covers/flatcolor_cover_bg.webp"
// import SportsCoverBg from "../../../../assets/oropuroV2/section_covers/sports_cover_bg.webp"
// import SportCover3x from "../../../../assets/oropuroV2/section_covers/sport_V2_cover@3x.webp";
// import SlotsCover3x from "../../../../assets/oropuroV2/section_covers/slots_cover@3x.webp";
// import CrashBg from "../../../../assets/oropuroV2/section_covers/crash_cover_bg.webp"
// import CrashCover3x from "../../../../assets/oropuroV2/section_covers/crash_cover@3x.webp";
// import LiveCasinoBg from "../../../../assets/oropuroV2/section_covers/live_casino_cover_bg.webp"
// import LiveCasinoCover3x from "../../../../assets/oropuroV2/section_covers/live_casino_v2_cover@3x.webp";
// import TableGamesBg from "../../../../assets/oropuroV2/section_covers/table_games_cover_bg.webp"
// import TableGamesCover3x from "../../../../assets/oropuroV2/section_covers/table_games_cover@3x.webp";

import CrashCover from '../../../../assets/bigcash/categories/crash_cover.webp'
import SlotCover from '../../../../assets/bigcash/categories/slots_cover.webp'
import LiveCasinoCover from '../../../../assets/bigcash/categories/live_casino_cover.webp'
import SportCover from '../../../../assets/bigcash/categories/sport_cover.webp'
import TableGamesCover from '../../../../assets/bigcash/categories/table_games_cover.webp'
import Stack from "@mui/material/Stack";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { useLanguage } from "../../../features/localisation/useLanguage";
import {SectionCardV2} from "./SectionCardV2";

export const GameSections: FC = () => {
    const { languagePack: {pack: {argentina: {headerMenu: menuLang, categoriesDescription: catDescLang}}}} = useLanguage()
    const { largeScreen  } = useCommonMediaQueries()

    return <Stack
        direction={'row'}
        width={'100%'}
        gap={largeScreen ? '10px' : '0.5vw'}
        px={'8px'}
        pb={'8px'}
        sx={{
            overflowX: 'auto',
            scrollbarWidth: 'thin',
            scrollbarColor: '#15181D #15181D10',
        }}
    >
        <SectionCardV2
            imageUrl={CrashCover}
            title={menuLang.crash}
            description={catDescLang.crash}
            url={'/crash'}
        />
        <SectionCardV2
            imageUrl={SlotCover}
            title={menuLang.slots}
            description={catDescLang.slots}
            url={'/slots'}
        />
        <SectionCardV2
            imageUrl={LiveCasinoCover}
            title={menuLang.liveCasino}
            description={catDescLang.liveCasino}
            url={'/live_casino'}
        />
        <SectionCardV2
            imageUrl={SportCover}
            title={menuLang.sport}
            description={catDescLang.sport}
            url={'/sport'}
        />
        <SectionCardV2
            imageUrl={TableGamesCover}
            title={menuLang.tableGames}
            description={catDescLang.tableGames}
            url={'/table_games'}
        />



        {/*<SectionCard*/}
        {/*    backgroundImgUrl={CrashBg}*/}
        {/*    imageUrl={{*/}
        {/*        small: CrashCover3x,*/}
        {/*        medium: CrashCover3x,*/}
        {/*        large: CrashCover3x*/}
        {/*    }}*/}
        {/*    title={menuLang.crash}*/}
        {/*    description={catDescLang.crash}*/}
        {/*    url={'/crash'}*/}
        {/*/>*/}
        {/*<SectionCard*/}
        {/*    backgroundImgUrl={FlatColorBg}*/}
        {/*    imageUrl={{*/}
        {/*        small: SlotsCover3x,*/}
        {/*        medium: SlotsCover3x,*/}
        {/*        large: SlotsCover3x*/}
        {/*    }}*/}
        {/*    title={menuLang.slots}*/}
        {/*    description={catDescLang.slots}*/}
        {/*    url={'/slots'}*/}
        {/*/>*/}
        {/*<SectionCard*/}
        {/*    backgroundImgUrl={LiveCasinoBg}*/}
        {/*    imageUrl={{*/}
        {/*        small: LiveCasinoCover3x,*/}
        {/*        medium: LiveCasinoCover3x,*/}
        {/*        large: LiveCasinoCover3x*/}
        {/*    }}*/}
        {/*    title={menuLang.liveCasino}*/}
        {/*    description={catDescLang.liveCasino}*/}
        {/*    url={'/live_casino'}*/}
        {/*/>*/}
        {/*<SectionCard*/}
        {/*    backgroundImgUrl={SportsCoverBg}*/}
        {/*    imageUrl={{*/}
        {/*        small: SportCover3x,*/}
        {/*        medium: SportCover3x,*/}
        {/*        large: SportCover3x*/}
        {/*    }}*/}
        {/*    title={menuLang.sport}*/}
        {/*    description={catDescLang.sport}*/}
        {/*    url={'/sport'}*/}
        {/*/>*/}
        {/*<SectionCard*/}
        {/*    backgroundImgUrl={TableGamesBg}*/}
        {/*    imageUrl={{*/}
        {/*        small: TableGamesCover3x,*/}
        {/*        medium: TableGamesCover3x,*/}
        {/*        large: TableGamesCover3x*/}
        {/*    }}*/}
        {/*    title={menuLang.tableGames}*/}
        {/*    description={catDescLang.tableGames}*/}
        {/*    url={'/table_games'}*/}
        {/*/>*/}





        {/*<SectionCard*/}
        {/*    backgroundImgUrl={ShootersBg}*/}
        {/*    imageUrl={{*/}
        {/*        small: ShootersCover,*/}
        {/*        medium: ShootersCover2x,*/}
        {/*        large: ShootersCover3x*/}
        {/*    }}*/}
        {/*    title={menuLang.shooters}*/}
        {/*    description={catDescLang.shooters}*/}
        {/*    url={'/shooters'}*/}
        {/*/>*/}


        {/*<SectionCard*/}
        {/*    backgroundImgUrl={FlatColorBg}*/}
        {/*    imageUrl={{*/}
        {/*        small: CasinoCover,*/}
        {/*        medium: CasinoCover2x,*/}
        {/*        large: CasinoCover3x*/}
        {/*    }}*/}
        {/*    title={menuLang.casino}*/}
        {/*    description={catDescLang.casino}*/}
        {/*    url={'/casino'}*/}
        {/*/>*/}





    </Stack>
}