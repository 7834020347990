import { FC } from "react";
import {Box, Stack, useTheme} from "@mui/material";
import { TopGameHolder } from "./models/models";
import { TopGamesList } from "./TopGamesList";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import TopGamesBanner from '../../../../assets/bigcash/top_games/top_games_banner.webp'

export const TopGamesSection: FC = () => {
    const theme = useTheme()
    const { largeScreen: lg } = useCommonMediaQueries()

    const domain = window.location.hostname;
    console.log(domain);

    // const isStaging = getProductStage() === 'staging';

    return <Box>
        <Box position={'relative'} pt={'27px'}>
            <Stack
                direction={'row'}
                py={'36px'}
                px={lg ? 0 : '2vw'}
                sx={lg ? {
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: theme.palette.primary.main,
                    backgroundImage: `#121316)`,
                    backgroundPosition: `center`,
                    backgroundSize: `auto`,
                    backgroundRepeat: 'no-repeat',
                } : {
                    backgroundColor: theme.palette.background.paper,
                    backgroundRepeat: 'no-repeat',
                }}
                borderRadius={'20px'}
                justifyContent={'space-between'}
                alignItems={'center'}
                gap={'2vw'}
            >
                {!lg && <Box
                    width={'22vw'}
                    borderRadius={'18px'}
                    sx={{
                        backgroundImage: `url(${TopGamesBanner})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        aspectRatio: '1/1'
                    }}
                />}
                <Box
                    width={lg ? '100%' : '68vw'}
                >
                    <TopGamesList gamesList={topGamesListProduction}/>
                </Box>
            </Stack>
        </Box>


    </Box>
}

const topGamesListProduction: TopGameHolder[] = [
    { gamePic: '/cdn/game_img/sqr_mid/bgaming.JokerQueen.webp', gameID: 7826 },
    { gamePic: '/cdn/game_img/sqr_mid/Tada.OceanHunter.webp', gameID: 10413 },
    { gamePic: '/cdn/game_img/sqr_mid/rubyplay.GoHighGallina.webp', gameID: 10677 },
    { gamePic: '/cdn/game_img/sqr_mid/Mancala.PowerofZeus.webp', gameID: 3809 },
    { gamePic: '/cdn/game_img/sqr_mid/amigo_HadesPower.webp', gameID: 8118 },
    { gamePic: '/cdn/game_img/sqr_mid/galaxsys.towerrash.jpg', gameID: 3852 },
    { gamePic: '/cdn/game_img/sqr_mid/bgaming.FishingClub.webp', gameID: 7819 },
    { gamePic: '/cdn/game_img/sqr_mid/PragmaticPlay.BigBassXmasXtreme.webp', gameID: 10700 },
    { gamePic: '/cdn/game_img/sqr_mid/Tada.SuperAce.webp', gameID: 10281 },
    { gamePic: '/cdn/game_img/sqr_mid/amigo_WildfireJoker.jpg', gameID: 8081 },
    { gamePic: '/cdn/game_img/sqr_mid/mancala.dracos_gold.webp', gameID: 3801},
    { gamePic: '/cdn/game_img/sqr_mid/3oaks.egypt_fire.jpg', gameID: 187 },
]