import { FC } from "react";
import { Button, Stack, useTheme } from "@mui/material";
import { FooterProps } from "./Footer";
import { Logo } from "../logo/Logo";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { useLanguage } from "../../../features/localisation/useLanguage";
import BreakedLine from "../../common/BreakedLine";

export const DesktopFooter: FC<FooterProps> = ({scrollContainerRef}) => {
    const theme = useTheme()
    const { languagePack: {pack: {argentina: {footer: footerLang}}}} = useLanguage()

    const scrollToTop = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    return <Stack direction={'row'} sx={{ backgroundColor: theme.palette.surface.main }} justifyContent={'space-between'} padding={'15px'}>
        <Link to={'/'}><Logo/></Link>
        <Typography lineHeight={1.2} fontWeight={400} textTransform={'uppercase'} textAlign={'center'}
                    sx={{ opacity: 0.6 }}>
            <BreakedLine text={footerLang.copyright}/>
        </Typography>
        <Button
            variant={'outlined'}
            sx={{
                color: 'grey.400',
                borderColor: 'grey.800',
                '&:hover': {
                    borderColor: 'grey.700',
                }
            }}
            onClick={scrollToTop}
        >
            {footerLang.backToTopBTN}
        </Button>
    </Stack>
}