import { FC } from "react";
import { Box } from "@mui/material";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import logo from '../../../../assets/bigcash/big_cash_logo.webp'

interface LogoProps {
    height?: number
}

export const Logo: FC<LogoProps> = ({height}) => {
    const {xxSmallScreen} = useCommonMediaQueries()
    const width = height ? height * 4.8 : undefined

    return <Box
        width={height ? `${height}px` : (xxSmallScreen ? '120px' : '196px')}
        height={(width ? `${width}px` : xxSmallScreen ? '25px' : '40px')}
        sx={{
            backgroundImage: `url(${logo})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
        }}
    />
}