import { OutcomeGame, OutcomeGameSession, OutcomeProvider } from "./game";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { transformGamesToOutcomeProviders } from "./funcs";

interface GamesState {
    providers: OutcomeProvider[]
    selectedProvider: OutcomeProvider|null
    gameSession: OutcomeGameSession
    isGamesLoading: boolean
    isGameSessionLoading: boolean
}

const initialState: GamesState = {
    providers: [],
    selectedProvider: null,
    gameSession: {
        SessionId: null,
        SessionUrl: null,
        SessionHTML: null
    },
    isGamesLoading: false,
    isGameSessionLoading: false
}

export const GamesSlice = createSlice({
    name: 'games',
    initialState,
    reducers: {
        setGames: (state, action: PayloadAction<OutcomeGame[]>) => {
            state.providers = transformGamesToOutcomeProviders(action.payload)
        },
        setSelectedProvider: (state, action: PayloadAction<OutcomeProvider>) => {
            state.selectedProvider = action.payload
        },
        clearSelectedProvider: (state) => {
            state.selectedProvider = null
        },
        setGameSession: (state, action: PayloadAction<OutcomeGameSession>) => {
            state.gameSession = action.payload
        },
        clearGameSession: (state) => {
            state.gameSession.SessionId = null
            state.gameSession.SessionUrl = null
        },
        setGamesLoading: (state, action: PayloadAction<boolean>) => {
            state.isGamesLoading = action.payload
        },
        setGameSessionLoading: (state, action: PayloadAction<boolean>) => {
            state.isGameSessionLoading = action.payload
        }
    }
})

export default GamesSlice.reducer

export const { setGames, setGameSession, setGameSessionLoading, setGamesLoading, setSelectedProvider, clearSelectedProvider, clearGameSession } = GamesSlice.actions