import { FC } from "react";
import { Box } from "@mui/material";
import { TopGameHolder } from "./models/models";
import { getCurrentDomain } from "../../../config";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";

interface TopGameCardProps {
    game: TopGameHolder
    onClick?: (game: TopGameHolder) => void
}

export const TopGameCard: FC<TopGameCardProps> = ({game, onClick}) => {
    const { xxSmallScreen, smallScreen, largeScreen } = useCommonMediaQueries()
    const cardHeight = xxSmallScreen ? '85px' : smallScreen ? '150px' : largeScreen ? '200px' : '10.6vw'

    return <Box
        onClick={onClick ? () => onClick(game) : undefined}
        sx={{
            backgroundImage: `url(${getCurrentDomain()}${game.gamePic})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            cursor: onClick ? 'pointer' : 'default',
        }}
        width={cardHeight}
        minWidth={cardHeight}
        height={cardHeight}
        borderRadius={'20px'}
    />
}