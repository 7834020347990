import {FC, useMemo} from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from "react-responsive-carousel";
import {Box} from "@mui/material";
import Slide1En from '../../../../assets/bigcash/carousel/slide1_en.webp'
import Slide1Es from '../../../../assets/bigcash/carousel/slide1_es.webp'
import Slide1Pt from '../../../../assets/bigcash/carousel/slide1_pt.webp'
import {Link} from "react-router-dom";
import {useCommonMediaQueries} from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import {useLanguage} from "../../../features/localisation/useLanguage";
import {Lang} from "../../../features/localisation/useLanguageInterface";

export const CarouselSlider: FC = () => {
    const {languagePack: {lang}} = useLanguage()
    type Slide = { link: string, imgUrl: string }
    const {smallScreen: sm} = useCommonMediaQueries()
    const slides = useMemo((): Slide[] => {
        switch (lang) {
            case Lang.ES:
                return [
                    {link: '/live_casino', imgUrl: Slide1Es},
                    {link: '/live_casino', imgUrl: Slide1Es},
                ]
            case Lang.PT:
                return [
                    {link: '/live_casino', imgUrl: Slide1Pt},
                    {link: '/live_casino', imgUrl: Slide1Pt},
                ]
            case Lang.EN:
            default:
                return [
                    {link: '/live_casino', imgUrl: Slide1En},
                    {link: '/live_casino', imgUrl: Slide1En},
                ]
        }
    }, [lang])

    return <Box
        mt={sm ? 1 : 2}
        mb={4}
        width={'100%'}
    >
        <Carousel
            showThumbs={false}
            autoPlay={true}
            infiniteLoop={true}
            showStatus={false}
            interval={6000}
        >
            {slides.map(slide => <Link to={slide.link}><Box
                borderRadius={'16px'}
                width={'100%'}
                sx={{
                    aspectRatio: '4/1.5',
                    backgroundImage: `url(${slide.imgUrl})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    cursor: 'pointer'
                }}
            /></Link>)}
        </Carousel>
    </Box>
}