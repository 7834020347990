import React, { useEffect, useRef, useState } from 'react';
import { Route, Routes } from "react-router-dom";
import { useLanguage } from "./app/features/localisation/useLanguage";
import { usePlayGames } from "./app/features/play_games/usePlayGames";
import { HomePage } from "./app/pages_oropuro/home/HomePage";
import { MediaqueryIndicator } from "./app/components/dev/MediaqueryIndicator";
import { GamesPage } from "./app/pages_oropuro/casino/GamesPage";
import { PersonalAreaPage } from "./app/pages_oropuro/personal_area/PersonalAreaPage";
import { UserInfoPage } from "./app/pages_oropuro/personal_area/user_info_page/UserInfoPage";
import { TransactionsPage } from "./app/pages_oropuro/personal_area/transaction_page/TransactionsPage";
import { GamesHistory } from "./app/pages_oropuro/personal_area/games_history/GamesHistory";
import { SportPage } from "./app/pages_oropuro/sport/SportPage";
import AgeVerificationDialog from "./app/components/oropuro_layout/age_confirmation/AgeVerificationDialog";
// @ts-ignore
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

const customStyle = {
    visibility : {
        desktop : {
            xOffset : '15',
            yOffset : '90',
            position : 'tr'
        },

        mobile : {
            xOffset : 15,
            yOffset : 65,
            position : 'tr'
        },
    }
};

export const App = () => {
    const { languagePack: {pack: {argentina: {headerMenu: menuLang}}}} = useLanguage()
    const { switchLanguageFromStore } = useLanguage()
    const [ initLoad, setInitLoad ] = useState<boolean>(true)
    const [initLoad2, setInitLoad2] = useState<boolean>(true)
    const { gameSession: { SessionUrl }, getGames, getProviders, providers, groupedGames } = usePlayGames()
    const tawkMessengerRef = useRef();

    const prevSessionUrl = useRef<string | null | undefined>();

    useEffect(() => {
        if (initLoad2) {
            if (groupedGames === null) {
                getGames()
            }
            if (providers === null) {
                getProviders()
            }
            setInitLoad2(false)
        }
    }, [getGames, getProviders, groupedGames, initLoad, initLoad2, providers])

    useEffect(() => {
        if (initLoad) {
            setInitLoad(false)
            switchLanguageFromStore()
        }
    }, [initLoad, switchLanguageFromStore])

    useEffect(() => {
        prevSessionUrl.current = SessionUrl;
    }, [SessionUrl])

    useEffect(() => {
        // @ts-ignore
        window.Tawk_API.onLoad = function(){
            // @ts-ignore
            if (window.Tawk_API) {
                // @ts-ignore
                if (typeof window.Tawk_API.hideWidget === 'function') {
                    // @ts-ignore
                    window.Tawk_API.hideWidget();
                    // // @ts-ignore
                    // tawkMessengerRef.current?.hideWidget();
                    // @ts-ignore
                    tawkMessengerRef.current?.minimize();
                }
            }
        };
    }, []);

    useEffect(() => {
        if (SessionUrl !== prevSessionUrl.current) {
            if (SessionUrl !== null) {
                // @ts-ignore
                tawkMessengerRef.current?.minimize();
            }
            // else {
            //     if (tawkMessengerRef.current !== null && tawkMessengerRef.current !== undefined) {
            //         // @ts-ignore
            //         if (window.Tawk_API && typeof window.Tawk_API.showWidget === 'function') {
            //             // @ts-ignore
            //             window.Tawk_API.showWidget();  // Показать виджет
            //         }
            //     }
            // }
        }

        prevSessionUrl.current = SessionUrl;
    }, [SessionUrl, tawkMessengerRef])

    return <>
        <MediaqueryIndicator/>
        <Routes>
            <Route path={'/'} element={<HomePage/>}/>
            <Route path={'/games'} element={<GamesPage title={menuLang.slots}/>}/>
            <Route path={'/casino'} element={<GamesPage gameType={'casino'} title={menuLang.casino}/>}/>
            <Route path={'/slots'} element={<GamesPage gameType={'slots'} title={menuLang.slots}/>}/>
            <Route path={'/live_casino'} element={<GamesPage gameType={'live_casino'} title={menuLang.liveCasino}/>}/>
            <Route path={'/shooters'} element={<GamesPage gameType={'shooter'} title={menuLang.shooters}/>}/>
            <Route path={'/table_games'} element={<GamesPage gameType={'table_games'} title={menuLang.tableGames} />}/>
            <Route path={'/crash'} element={<GamesPage gameType={'crash'} title={menuLang.crash}/>}/>
            <Route path={'/sport'} element={<SportPage/>}/>

            <Route path={"/dashboard"} element={<PersonalAreaPage/>}/>
            <Route path={"/dashboard/user_info"} element={<UserInfoPage/>}/>
            <Route path={"/dashboard/transactions"} element={<TransactionsPage/>}/>
            <Route path={"/dashboard/game_history"} element={<GamesHistory/>}/>
        </Routes>

        <AgeVerificationDialog/>

        <TawkMessengerReact
            propertyId="676977dfaf5bfec1dbe0b4fc"
            widgetId="1ifpvog9a"
            customStyle={customStyle}
            ref={tawkMessengerRef}
            onBeforeLoad={() => {
                // @ts-ignore
                window.Tawk_API.hideWidget();
            }}
        />
    </>
}

export default App;
