import React, {FC, useCallback, useEffect} from "react";
import { Box } from "@mui/material";
import s from "./GameWindow.module.css";
import { usePlayGames } from "../../features/play_games/usePlayGames";
import IconButton from "@mui/material/IconButton";
import { setGameSession } from "../../features/play_games/playGamesSlice";
import CloseIcon from "@mui/icons-material/Close";
import { useAppDispatch } from "../../store";
import { useUser } from "../../features/user/useUser";
import HTMLGameFrame from "./HTMLGameFrame";

interface GameWindowProps {
    topOffset?: number
}

export const GameWindow: FC<GameWindowProps> = ({topOffset = 0}) => {
    const { gameSession } = usePlayGames()
    const { refreshUserBalance } = useUser()
    const dispatch = useAppDispatch()
    const isGSP = gameSession.SessionId === "GSP"

    const handleGameClose = useCallback(() => {
        dispatch(setGameSession({SessionUrl: null, SessionId: null, SessionHTML: null}))
        refreshUserBalance()
    }, [dispatch, refreshUserBalance])

    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
            const { type } = event.data

            switch (type) {
                // case 'rgs-gameChange':
                //     console.log('rgs-gameChange', event.data)
                //     break
                case 'rgs-backToHome':
                    // console.log('rgs-backToHome', event.data)
                    handleGameClose()
                    break
            }
        };

        window.addEventListener("message", handleMessage);

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, [handleGameClose]);

    return <Box pt={'50px'} position={'fixed'} left={0} right={0} top={topOffset} bottom={0} height={'100%'} sx={{backgroundColor: 'black', zIndex: 999}} display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <Box
            position={'absolute'}
            zIndex={'9999'}
            height={'40px'}
            top={'10px'}
            right={'20px'}
            display={'flex'}
            justifyContent={'flex-end'}
        >
            <IconButton
                edge="start"
                color="inherit"
                onClick={handleGameClose}
                aria-label="close"
            >
                <CloseIcon />
            </IconButton>
        </Box>
                {gameSession.SessionHTML ? <HTMLGameFrame/> : gameSession.SessionUrl && <iframe
                    sandbox={isGSP ? undefined : "allow-same-origin allow-scripts allow-forms allow-top-navigation"}
                    className={s.gameFrame}
                    src={gameSession.SessionUrl}
                    allowFullScreen={true}
                    allow="autoplay"
                    title={gameSession.SessionId ?? ''}
                />}
    </Box>
}