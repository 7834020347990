import { FC } from "react";
import {alpha, Box, Stack, SvgIconTypeMap, useTheme} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import Typography from "@mui/material/Typography";
import { normalizePath } from "../../../features/common_funcs";
import { Link } from "react-router-dom";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";

interface BottomMobileMenuItemProps {
    children: string
    IconComponent: OverridableComponent<SvgIconTypeMap<{}, "svg">>
    link: string
}

export const BottomMobileMenuItem: FC<BottomMobileMenuItemProps> = ({ children, link, IconComponent }) => {
    const theme = useTheme()
    const currentPath = normalizePath(window.location.pathname)
    const isActive = currentPath === normalizePath(link)
    const { mediumScreen } = useCommonMediaQueries()

    return <Box flex={1}>
        <Link to={link}>
            <Stack
                width={'100%'}
                height={'100%'}
                sx={{
                    backgroundColor: isActive ? alpha(theme.palette.primary.main, 0.2) : 'transparent',
                    cursor: isActive ? 'default' : 'pointer'
                }}
                padding={'2px'}
                alignItems={'center'}
                borderRadius={'12px'}
            >
                <IconComponent
                    sx={{
                        color: isActive ? 'primary.main' : alpha('#fff', 0.6),
                        transition: 'color 0.3s',
                    }}
                />
                <Typography fontSize={mediumScreen ? '3.5vw' : '28px'} sx={{ userSelect: 'none' }}
                            color={isActive ? 'primary.main' : alpha('#fff', 0.6)} lineHeight={1.2}>
                    {children}
                </Typography>
            </Stack>
        </Link>
    </Box>
}