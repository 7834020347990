import { FC, useRef } from "react";
import {usePlayGames} from "../../features/play_games/usePlayGames";
import s from "./GameWindow.module.css";

const HTMLGameFrame: FC = () => {
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const { gameSession } = usePlayGames();

    const srcDocContent = gameSession?.SessionHTML ? `
      <!DOCTYPE html>
      <html>
        <head>
          <style>
            html, body {
              height: 100%;
              margin: 0;
              padding: 0;
              overflow: hidden;
            }
          </style>
        </head>
        <body>
          ${gameSession.SessionHTML}
        </body>
      </html>
    ` : '';

    return (
        <iframe
            srcDoc={srcDocContent}
            className={s.gameFrame}
            ref={iframeRef}
            title="Game Content"
        />
    );
}

export default HTMLGameFrame;
