import React, { FC, useState } from "react";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import {Box, Dialog, DialogContent, Slide, Stack} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { LoginForm } from "../../common/login/LoginForm";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {Logo} from "../logo/Logo";
import SquareLogo from '../../../../assets/bigcash/logo_square-512x512.png'

interface LoginWindowProps {
    close?: () => void
}

export const LoginWindow: FC<LoginWindowProps> = ({ close }) => {
    const { xxSmallScreen } = useCommonMediaQueries()
    const [ opened, setOpened ] = useState(true)

    const pendingClose = () => {
        setOpened(false);
        if (close) {
            setTimeout(() => close(), 500)
        }
    }

    return <Dialog
        open={opened}
        fullScreen={xxSmallScreen}
        onClose={pendingClose}
        TransitionComponent={Transition}
        sx={{
            '& .MuiDialog-paper': {
                backgroundImage: 'linear-gradient(180deg, #F0AB3D55 0%, #F0AB3D00 20%, #F0AB3D00 80%, #F0AB3D55 100%)',
            }
        }}
    >
        <DialogContent>
            <Stack
                justifyContent={'center'}
                height={'100%'}
                gap={'14px'}
                position={'relative'}

            >
                <Box
                    flex={1}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'end'}
                    pb={'20px'}
                >
                    <Logo/>
                </Box>
                <LoginForm onDone={pendingClose}/>
                <Box
                    flex={2}
                    sx={{
                        backgroundImage: `url(${SquareLogo})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: '50% 20%',
                        backgroundSize: '50%',
                    }}
                />
                {xxSmallScreen && close && <IconButton sx={{ position: 'absolute', top: 0, right: 0 }} onClick={close}>
                    <HighlightOffIcon fontSize={'large'}/>
                </IconButton>}
            </Stack>
        </DialogContent>
    </Dialog>
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});