import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import {InputAdornment, LinearProgress, Modal, Paper, useTheme} from "@mui/material";
import { useCommonMediaQueries } from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { usePlayGames } from "../../features/play_games/usePlayGames";
import SearchIcon from "@mui/icons-material/Search";
import { GameObj } from "../../features/play_games/playGamesModels";
import { GameCard } from "./games/GameCard";
import { getCurrentDomain } from "../../config";
import { useSnackbar, VariantType } from "notistack";
import { useUser } from "../../features/user/useUser";
import Typography from "@mui/material/Typography";

const minSearchTextLength = 3

interface GameSearchWindowProps {
    onClose: () => void
}

export const GameSearchWindow: FC<GameSearchWindowProps> = ({ onClose }) => {
    const theme = useTheme()
    const { xxSmallScreen, largeScreen } = useCommonMediaQueries()
    const { gameSession, isGameSessionLoading, games, startGameSession } = usePlayGames()
    const [ searchText, setSearchText ] = useState<string>('')
    const [ foundGames, setFoundGames ] = useState<GameObj[]>([])
    const currentDomain = getCurrentDomain();
    const { enqueueSnackbar } = useSnackbar();
    const { token } = useUser()
    const scrollRef = useRef<HTMLDivElement>(null);
    const isWheelListenerAttached = useRef(false); // Флаг для отслеживания установки обработчика

    useEffect(() => {
        const element = scrollRef.current;

        const onWheel = (event: WheelEvent) => {
            if (event.deltaY !== 0 && element) {
                event.preventDefault();
                element.scrollLeft += event.deltaY;
            }
        };

        if (element && !isWheelListenerAttached.current) {
            element.addEventListener('wheel', onWheel);
            isWheelListenerAttached.current = true;
        }

        return () => {
            if (element && isWheelListenerAttached.current) {
                element.removeEventListener('wheel', onWheel);
                isWheelListenerAttached.current = false;
            }
        };
    }, [foundGames]);

    const sendSnackBar = (variant: VariantType, message: string) => {
        enqueueSnackbar(message, { variant });
    };

    const clickHandler = () => {
        return token ? (game: GameObj) => {
            if (!isGameSessionLoading) {
                startGameSession(game.internal_id)
            }
        } : () => {
            console.log('Login required')
            sendSnackBar('warning', 'Login required')
        }
    }

    const clickHandlerMobile = (game: GameObj) => {
        return token ? () => {
            if (!isGameSessionLoading) {
                startGameSession(game.internal_id)
            }
        } : () => {
            console.log('Login required')
            sendSnackBar('warning', 'Login required')
        }
    }

    const handleSearchTextChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        setSearchText(value)
        if (games !== null) {
            if (value.trim().length >= minSearchTextLength) {
                const filteredGames = games.filter(game => game.Name.toLowerCase().includes(value.toLowerCase()))
                setFoundGames(filteredGames)
            } else {
                setFoundGames([])
            }
        }
    }

    return <Modal
        sx={{
            backgroundColor: 'rgba(65,65,65,0.35)',
            backdropFilter: 'blur(2px)',
        }}
        open={!Boolean(gameSession.SessionUrl)}
        onClose={onClose}
    >
        { xxSmallScreen ?
            <Stack
                position={'fixed'}
                top={0}
                left={0}
                right={0}
                sx={{
                    backgroundColor: theme.palette.background.paper
                }}
            >
                <TextField
                    sx={{
                        '& .MuiInput-input': {
                            padding: '14px'
                        },
                        '& .MuiInputAdornment-root': {
                            padding: '14px',
                        },
                    }}
                    variant={'standard'}
                    value={searchText}
                    onChange={handleSearchTextChanged}
                    placeholder={'Type game name'}
                    slotProps={{
                        input: {
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon color="primary" />
                                </InputAdornment>
                            ),
                        },
                    }}
                />
                <Stack sx={{overflowY: 'auto'}} maxHeight={'40vh'}>
                    <Stack gap={'8px'}>
                        {foundGames.map(game => <Stack sx={{cursor: 'pointer'}} px={'14px'} direction={'row'} key={game.internal_id} onClick={clickHandlerMobile(game)} gap={'8px'} alignItems={'center'}>
                            <GameCard
                                height={'50px'}
                                currentDomain={currentDomain ?? ""}
                                game={game}
                            />
                            <Typography fontSize={'20px'} sx={{userSelect: 'none'}}>
                                {game.Name}
                            </Typography>
                        </Stack>)}
                    </Stack>
                </Stack>
            </Stack>
            :
            <Paper
                sx={{
                    transform: 'translate(-50%, -50%)',
                    outline: 'none',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    minWidth: '700px',
                    width: largeScreen ? '80vw' : '55vw',
                    padding: '12px',
                    borderRadius: '20px'
                }}
            >
                <Stack gap={'8px'}>
                    <TextField
                        disabled={isGameSessionLoading}
                        value={searchText}
                        onChange={handleSearchTextChanged}
                        placeholder={'Type game name'}
                        variant={'outlined'}
                        slotProps={{
                            input: {
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon color="primary" />
                                    </InputAdornment>
                                ),
                            },
                        }}
                    />
                    {isGameSessionLoading && <LinearProgress/>}
                    <Stack
                        width={'100%'}
                        ref={scrollRef}
                        direction={'row'}
                        gap={'6px'}
                        pb={'2px'}
                        sx={{
                            overflowX: 'auto',
                            whiteSpace: 'nowrap',
                            scrollbarWidth: 'thin',
                            scrollbarColor: '#15181D #15181D60',
                        }}>
                        {foundGames.map(game => <GameCard
                            height={'100px'}
                            key={game.internal_id}
                            currentDomain={currentDomain ?? ""}
                            game={game}
                            onGameClick={clickHandler()}
                        />)}
                    </Stack>
                </Stack>
            </Paper>}
    </Modal>
}